@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #eeeeee;
}

.main-grid {
  max-height: 60px;
  display: grid;
  grid-template-rows: 1fr 70px;
}

.main-grid__container-list {
  display: grid;
  grid-template-columns: 50% 50%;
}
.main-grid__list {
  overflow-y: auto;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 20px;
}

.main-grid__sidebar-grid {
  height: 100% !important;
  padding: 0px 20px 0px 20px;
}

.sidebar-container {
  display: grid;
  grid-template-rows: 1fr 80px;
}

.category-icon {
  display: grid;
  grid-template-columns: 70px 5px;
}

.MuiPaper-root.selected {
  background-color: #b9a8f7;
}
